import {
  Breadcrumb,
  Button,
  Image as AntdImage,
  Input,
  Modal,
  Table,
  Tag,
  Select,
  message,
  InputNumber,
  Checkbox,
  Popover,
  InputRef,
  InputProps,
  Space,
} from "antd";
import {
  Text,
  Wrapper,
  Image,
} from "../../../components/common/commonComponents";
import {
  EyeOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ShoppingCartOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import colorConfigs from "../../../configs/colorConfigs";
import { ColumnGroupType } from "antd/lib/table";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  createCartFetcher,
  getCategoryFetcher,
  getProductFetcher,
} from "../../../utils/fetcher";
import { as_clnt } from "../../../configs/clientConfig";
import { LoadNotification, numberWithCommas } from "../../../utils/commonUtils";
import { currentCategoryState } from "../../../state/category";
import { useRecoilValue } from "recoil";
import { Category } from "../../../components/common/Sidebar/_sidebar";
import { stringify } from "querystring";
import { baseURL } from "../../../routes/routeConfig";
import styled from "styled-components";
import Cart from "../_mc1200/Cart";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import useWidth from "../../../hooks/useWidth";
import useInput from "../../../hooks/useInput";

const CustomImage = ({
  srcS,
  srcM,
  width,
  height,
  objectFit,
}: {
  srcS: string;
  srcM: string;
  previewSrc: string;
  width?: string;
  height?: string;
  objectFit?: "contain" | "cover" | "fill" | "none" | "scale-down";
}) => {
  const [isError, setIsError] = useState(false);

  return (
    <AntdImage
      src={srcS}
      fallback="/images/image.png"
      preview={{ src: srcM }}
      style={{
        width: width ? width : "",
        height: height ? height : "",
        objectFit: objectFit ? objectFit : "contain",
      }}
    />
  );
};

const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
  }
`;
export interface Product {
  rtn_able?: number;
  rtn_b2bcls?: string;
  rtn_b2bclsnm?: string;
  rtn_colornm?: string;
  rtn_img_m?: string;
  rtn_img_s?: string;
  rtn_itmcd?: string;
  rtn_itmcdnm?: string;
  rtn_itmid?: number;
  rtn_makernm?: string;
  rtn_originm?: string;
  rtn_rtlprc?: number;
  rtn_salprc?: number;
  rtn_stock?: number;
  rtn_sublist: ProductSub[];
}
export interface ProductSub {
  displaynm: string;
  itm?: string;
  stock: number;
  sznm?: string;
  szseq?: number;
}

const ProductList = () => {
  const currentCategory = useRecoilValue(currentCategoryState);
  const [noticeModal, setNoticeModal] = useState<boolean>(false);
  const [noticemsg, setNoticeMsg] = useState<string>("");
  const [noticeid, setNoticeId] = useState<string>("");
  const [noteheader, setNoteHeader] = useState<string>("");
  const width = useWidth();
  const [qs, setQs] = useState("");
  const [currentCartBtn, setCurrentCartBtn] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedRow, setSelectedRow] = useState<Product[] | []>([]);
  const searchRef = useRef<InputRef>(null);
  const inputSearch = useInput("");
  const [breadCrumb, setBreadCrumb] = useState<Category[]>([]);
  const [size, setSize] = useState<
    {
      itmcd: string;
      id: number;
      item_id: string;
      size: string;
      stock: number;
    }[]
  >([]);
  const [qty, setQty] = useState<{ itmcd: string; qty: number }[]>([]);
  const [list, setList] = useState<Product[]>([]);
  const [page, setPage] = useState(1);
  const movePage = useNavigate();
  const location = useLocation();
  const myinfo = localStorage.getItem("SOWJDQH");
  const ls_today = new Date().toISOString().split("T")[0];
  const {
    data: productData,
    refetch,
    isLoading,
    isFetching,
  } = useQuery(["getProductFetcher", qs], () => getProductFetcher(qs), {
    enabled: false,
    staleTime: Infinity,
    refetchInterval: false,
    retry: false,
    refetchOnWindowFocus: false,
  });
  const { data: categoryData } = useQuery("getCategoryFetcher", () =>
    getCategoryFetcher(`?as_clnt=${as_clnt}`)
  );
  const [previewWidthVisible, setPreviewWidthVisible] = useState(0);

  const handlePreviewClose = (e: boolean) => {
    if (e === true) {
      setTimeout(() => {
        setPreviewWidthVisible(600);
      }, 400);
    } else {
      setPreviewWidthVisible(0);
    }
  };

  const onSelectChange = useCallback(
    (e: CheckboxChangeEvent, newValue: Product) => {
      if (newValue?.rtn_itmcd && typeof newValue?.rtn_itmcd === "string") {
        if (e.target.checked) {
          const _size = size.find((sz) => sz.itmcd === newValue.rtn_itmcd);
          const _qty = qty.find((dt) => dt.itmcd === newValue.rtn_itmcd);
          if (!_size) {
            return LoadNotification("[SYSTEM]", `사이즈를 선택해 주세요.`, 0);
          }

          if (
            typeof _size?.stock === "number" &&
            typeof _qty?.qty === "number" &&
            _size?.stock < _qty?.qty
          ) {
            return LoadNotification(
              "[SYSTEM]",
              `주문가능수량을 초과했습니다. 가능수량은 ${_size?.stock} EA 입니다.`,
              0
            );
          } else {
            let selected_row = [...selectedRow];
            selected_row.push(newValue);
            setSelectedRow(selected_row);
          }
        } else {
          let selected_row = [...selectedRow];
          const filtered_value = selected_row.filter(
            (dt) => dt.rtn_itmcd !== newValue.rtn_itmcd
          );
          setSelectedRow(filtered_value);
        }
      }
    },
    [selectedRow, qty, size]
  );

  const searchHandler = useCallback(async (e) => {
    setSearchValue(e);
  }, []);

  const addToCartSuccess = useCallback(
    (data, variables) => {
      const jsondata =
        variables?.as_jsondata && JSON.parse(variables?.as_jsondata);

      if (jsondata && jsondata.length !== 0) {
        if (currentCartBtn) {
          // 개별 추가 후처리
          const _size = [...size];
          const __size = _size.filter((data) => data.itmcd !== currentCartBtn);
          setSize(__size);
          setCurrentCartBtn(null);
          setQty((prev) =>
            prev.map(
              (item) =>
                item.itmcd === currentCartBtn
                  ? { ...item, qty: 1 } // 새로운 값으로 업데이트
                  : item // 기존 객체 반환
            )
          );
          const _selectedRow = [...selectedRow].filter(
            (data: Product) => data.rtn_itmcd !== currentCartBtn
          );
          setSelectedRow(_selectedRow);
        } else {
          // 선택 추가 후처리

          setSize([]);
          setQty((prev) => prev.map((item) => ({ ...item, qty: 1 })));
          setSelectedRow([]);
        }
        LoadNotification(
          "[SYSTEM]",
          "해당 상품이 장바구니에 추가되었습니다.",
          1
        );
      } else {
        LoadNotification("[SYSTEM]", "상품 선택 후 추가해주세요.", 0);
      }
    },
    [size, qty, currentCartBtn, selectedRow]
  );

  const addToCartHandler = useCallback(
    async (data) => {
      await setCurrentCartBtn(data.rtn_itmcd);
      const _myinfo = myinfo && JSON.parse(myinfo);
      const selectedSize = size.find((item) => item.itmcd === data.rtn_itmcd);
      const ordqty = qty.find((item) => item.itmcd === data.rtn_itmcd);
      if (!selectedSize) {
        return LoadNotification(
          "[SYSTEM]",
          "옵션 선택후 장바구니에 추가해주세요.",
          0
        );
      } else if (!ordqty || !ordqty.qty) {
        return LoadNotification(
          "[SYSTEM]",
          "수량 선택후 장바구니에 추가해주세요.",
          0
        );
      } else if (ordqty.qty > selectedSize.stock) {
        return LoadNotification(
          "[SYSTEM]",
          `주문가능수량을 초과했습니다. 가능수량은 ${selectedSize.stock} EA 입니다.`,
          0
        );
      } else {
        const req_Data = {
          as_clnt,
          as_cust: _myinfo?.rtn_ocust,
          as_jsondata: JSON.stringify([
            {
              clnt: _myinfo?.rtn_clnt,
              emp: _myinfo?.rtn_emp,
              cust: _myinfo?.rtn_ocust,
              itm: selectedSize?.item_id || null,
              itmid: data.rtn_itmid,
              color: data.rtn_colornm,
              size: selectedSize?.size || null,
              ordqty: ordqty.qty,
              ordprc: data.rtn_salprc,
            },
          ]),
        };

        mutate(req_Data);
      }
    },
    [as_clnt, myinfo, size, qty]
  );
  const { mutate, isLoading: addToCartLoading } = useMutation({
    mutationFn: createCartFetcher,
    onSuccess: addToCartSuccess,
  });

  const addAllToCartHandler = useCallback(() => {
    const _myinfo = myinfo && JSON.parse(myinfo);
    // const selectedSize = size.find((item) => item.itmcd === data.rtn_itmcd);
    // const ordqty = qty.find((item) => item.itmcd === data.rtn_itmcd);

    // const filtered_qty = [...qty].filter((itm) =>
    //   selectedRow.some((srk) => itm.itmcd === srk.rtn_itmcd)
    // );
    // const filtered_size = [...size].filter((itm) =>
    //   selectedRow.some((srk) => itm.itmcd === srk.rtn_itmcd)
    // );
    const jsondata = selectedRow
      .map((sr: Product) => {
        const selectedSize = size.find((data) => data.itmcd === sr.rtn_itmcd);
        const selectedQty = qty.find((data) => data.itmcd === sr.rtn_itmcd);

        if (selectedSize && selectedQty && _myinfo && sr) {
          return {
            clnt: _myinfo?.rtn_clnt,
            emp: _myinfo?.rtn_emp,
            cust: _myinfo?.rtn_ocust,
            itm: selectedSize?.item_id,
            itmid: sr.rtn_itmid,
            color: sr.rtn_colornm,
            size: selectedSize?.size,
            ordqty: selectedQty.qty,
            ordprc: sr.rtn_salprc,
          };
        } else {
          return;
        }
      })
      .filter(Boolean);

    const req_Data = {
      as_clnt,
      as_cust: _myinfo?.rtn_ocust,
      as_noticeyn: _myinfo?.rtn_noticeyn,
      as_noticemsg: _myinfo?.rtn_noticemsg,
      as_noteheader: _myinfo?.rtn_header,
      as_jsondata: JSON.stringify(jsondata),
    };

    mutate(req_Data);
  }, [as_clnt, myinfo, size, qty, selectedRow]);

  const sizeHandler = useCallback(
    (e, data) => {
      let tempArr = [...size];
      let foundIndex = tempArr.findIndex(
        (item) => item.itmcd === data.rtn_itmcd
      );
      const parseData = e && JSON.parse(e); // 현재 선택한 옵션값

      if (foundIndex !== -1) {
        tempArr[foundIndex].id = data.rtn_itmid; // 상품 id
        tempArr[foundIndex].itmcd = data.rtn_itmcd; // 상품 code
        tempArr[foundIndex].item_id = parseData?.itm; // 옵션 id
        tempArr[foundIndex].size = `${parseData?.sznm}`; // 옵션명
        tempArr[foundIndex].stock = parseData?.stock; // 옵션 재고
      } else {
        tempArr.push({
          id: data.rtn_itmid, // 상품 id
          itmcd: data.rtn_itmcd, // 상품 code
          item_id: parseData?.itm, // 옵션 id
          size: parseData?.sznm, // 옵션명
          stock: parseData?.stock, // 옵션 재고
        });
      }

      setSize(tempArr);
    },
    [size, qty]
  );

  const qtyHandler = useCallback(
    (e, data) => {
      let tempArr = [...qty];
      let foundIndex = tempArr.findIndex(
        (item) => item.itmcd === data.rtn_itmcd
      );

      if (foundIndex !== -1) {
        tempArr[foundIndex].itmcd = data.rtn_itmcd;
        tempArr[foundIndex].qty = e;
      } else {
        tempArr.push({
          itmcd: data.rtn_itmcd,
          qty: e,
        });
      }

      setQty(tempArr);
    },
    [qty]
  );

  const isChecked = useCallback(
    (dt) => {
      // record?.rtn_itmcd가 string 타입일 것으로 추론
      const rtn_itmcd = dt;
      const res =
        rtn_itmcd &&
        typeof rtn_itmcd === "string" &&
        selectedRow.findIndex((dt) => dt.rtn_itmcd === rtn_itmcd) !== -1;

      return res;
    },
    [selectedRow]
  );

  const isnolook = (noticeId: string) => {
    localStorage.setItem("isnolookDate", noticeId + ls_today);
    const prtdt = localStorage.getItem("isnolookDate");
  };

  const uf_noteheader = (noteheader: string) => {
    console.log(noteheader);
    if (noteheader !== "") {
      localStorage.setItem("lstr_noteheader", noteheader);
    } else {
      const _noteheader = localStorage.getItem("lstr_noteheader")||"";
      setNoteHeader(_noteheader);
    }
  };

  useEffect(() => {
    let _qs = "";
    const as_clnt_data = as_clnt;
    const _myinfo = myinfo && JSON.parse(myinfo);
    const as_cust = _myinfo?.rtn_ocust || "";
    const as_noticeid = _myinfo?.rtn_noticeyn || "";
    const as_noticemsg = _myinfo?.rtn_noticemsg || "";
    const as_noteheader = _myinfo?.rtn_header || "";
    setNoticeId(as_noticeid);
    setNoticeMsg(as_noticemsg);
    setNoteHeader(as_noteheader);
    uf_noteheader(as_noteheader);
    setPage(1);
    if (categoryData && location?.search && location?.search !== "") {
      _qs = `${location?.search}&as_searchgb=CLS&as_search=${searchValue}&as_clnt=${as_clnt_data}&as_cust=${as_cust}`;
    } else {
      const str_nolookdt = localStorage.getItem("isnolookDate");
      if (as_noticeid > "0" && str_nolookdt !== as_noticeid + ls_today) {
        setNoticeModal(true);
      }
      _qs = `?as_searchgb=CLS&as_b2bcls=${
        categoryData?.rtn_list?.[0]?.first_rtn_b2bcls
          ? categoryData?.rtn_list?.[0]?.first_rtn_b2bcls
          : ""
      }&as_search=${searchValue}&as_clnt=${as_clnt_data}&as_cust=${as_cust}`;
    }
    setQs(_qs);
  }, [location.search, searchValue, categoryData]);

  useEffect(() => {
    if (productData?.rtn_list) {
      const cd = productData?.rtn_list?.map((item: Product) => ({
        itmcd: item.rtn_itmcd,
        qty: 1,
      }));
      setQty(cd);
    } else {
      setQty([]);
    }
  }, [productData]);

  useEffect(() => {
    const search = location?.search;
    const search_value = search.split("=")[1];
    const temp_arr = [];

    if (search_value) {
      if (search && search !== "") {
        for (let i = 0; i < search_value.length; i++) {
          const substring = search_value.slice(0, i + 1);

          const findData = currentCategory?.find(
            (data: Category) => data.rtn_b2bcls === substring
          );

          findData && temp_arr.push(findData);
        }
      }
    }
    setBreadCrumb(temp_arr);
  }, [location, currentCategory]);

  useEffect(() => {
    searchHandler("");
    inputSearch.setValue("");
  }, [location?.search]);

  useEffect(() => {
    if (qs !== "") {
      refetch();
    }
  }, [qs]);

  const columns: ColumnsType<Product> = [
    {
      title: "이미지",
      width: width < 950 ? 100 : 150,
      align: "center",
      render: (data) => {
        return (
          <AntdImage
            src={data?.rtn_img_s}
            fallback="/images/image.png"
            preview={{
              src: data?.rtn_img_m,
              width: width < 950 ? `80%` : previewWidthVisible,
              destroyOnClose: true,
              onVisibleChange: (e) => handlePreviewClose(e),
            }}
          />
        );
      },
    },
    {
      title: "상품분류",
      align: "center",
      render: (data) => {
        return (
          <Tag>
            <Text>{data?.rtn_b2bclsnm}</Text>
          </Tag>
        );
      },
    },
    {
      title: "상품코드",
      align: "center",
      render: (data) => {
        return <Text>{data?.rtn_itmcd}</Text>;
      },
    },
    {
      title: "품명 / 제조사 / 원산지",
      align: "center",
      render: (data) => {
        return (
          <Wrapper al={`flex-start`} ju={`flex-start`}>
            <Text>{data?.rtn_itmcdnm}</Text>
            <Text>{data?.rtn_makernm}</Text>
            <Text>{data?.rtn_originm}</Text>
          </Wrapper>
        );
      },
    },
    {
      title: "소비자가",
      align: "center",
      render: (data) => {
        return <Text>{numberWithCommas(data?.rtn_rtlprc)}원</Text>;
      },
    },
    {
      title: "판매가",
      align: "center",
      render: (data) => {
        return <Text>{numberWithCommas(data?.rtn_salprc)}원</Text>;
      },
    },
    {
      title: "사이즈",
      align: "center",
      render: (data, record: Product) => {
        const dt = record?.rtn_itmcd;
        const checked = dt ? isChecked(dt) : false;
        const selectedSize =
          size.find((item) => item.itmcd === data.rtn_itmcd)?.size || null;

        const Size = (
          <Select
            disabled={checked}
            style={{ width: `120px` }}
            value={selectedSize}
            onChange={(e) => sizeHandler(e, data)}
          >
            {data?.rtn_sublist?.map((data: ProductSub, index: number) => {
              return (
                <Select.Option
                  key={index}
                  value={data && JSON.stringify(data)}
                  disabled={data?.stock === 0}
                >
                  {data?.displaynm}
                </Select.Option>
              );
            })}
          </Select>
        );
        if (!checked) {
          return Size;
        } else {
          return (
            <Popover title={`정보`} content={`수정하려면 선택을 해제해주세요.`}>
              {Size}
            </Popover>
          );
        }
      },
    },
    {
      title: "수량",
      align: "center",
      render: (data, record: Product, index) => {
        const dt = record?.rtn_itmcd;
        const checked = dt ? isChecked(dt) : false;
        const defualtQty =
          qty.find((item) => item.itmcd === data.rtn_itmcd)?.qty || undefined;
        const input = (
          <InputNumber
            disabled={checked}
            min={1}
            value={defualtQty}
            onChange={(e) => qtyHandler(e, data)}
          />
        );
        if (!checked) {
          return input;
        } else {
          return (
            <Popover title={`정보`} content={`수정하려면 선택을 해제해주세요.`}>
              {input}
            </Popover>
          );
        }
      },
    },
    {
      title: "선택",
      align: "center",
      render: (_: any, record: Product, idx) => {
        const dt = record?.rtn_itmcd;
        const checked = dt ? isChecked(dt) : false;

        return (
          <CustomCheckbox
            checked={checked}
            onChange={(e) => onSelectChange(e, record)}
          />
        );
      },
    },

    {
      align: "center",
      width: "90px",
      render: (data, record: Product, idx) => {
        const dt = record?.rtn_itmcd;
        // const checked = dt ? isChecked(dt) : false;
        return (
          <Button
            // disabled={checked}
            type={`primary`}
            ghost
            onClick={() => addToCartHandler(data)}
            loading={addToCartLoading}
          >
            장바구니
          </Button>
        );
      },
    },
  ];
  return (
    <Wrapper al={`flex-start`} ju={`flex-start`} height={`100%`}>
      <Wrapper
        dr={`row`}
        ju={`space-between`}
        bgColor={colorConfigs.basic.white}
        shadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
        padding={width < 950 ? `15px` : `30px`}
        radius={`10px`}
        margin={`0 0 50px`}
      >
        <Wrapper al={`flex-start`} margin={`0 0 10px`}>
          <Breadcrumb
            // style={{ fontSize: `20px` }}
            items={[
              { title: "상품 목록" },
              ...breadCrumb.map((data) => ({
                title: data.rtn_b2bclsnm,
                // onClick: () =>
                //   movePage(`${baseURL}/product/list?as_b2bcls=${data.rtn_b2bcls}`),
              })),
            ]}
          />
        </Wrapper>
        <Wrapper width={width < 950 ? `100%` : `calc(100% - 350px)`} dr={`row`}>
          <Input.Search
            {...inputSearch}
            size={`large`}
            placeholder="검색"
            ref={searchRef}
            onSearch={(value: string) => searchHandler(value)}
          />
        </Wrapper>
        <Wrapper
          width={width < 950 ? `100%` : `auto`}
          al={`flex-end`}
          margin={width < 950 ? `10px 0 0` : `0`}
        >
          <Button
            size={width < 950 ? `small` : `large`}
            color=""
            icon={
              <ShoppingCartOutlined
                style={{ fontSize: width < 950 ? `14px` : `20px` }}
              />
            }
            type="primary"
            ghost
            onClick={() => addAllToCartHandler()}
            loading={addToCartLoading}
          >
            장바구니에 추가
          </Button>
        </Wrapper>
      </Wrapper>
      <Text
        color={`#B00020`}
        fontSize={width < 950 ? `16px` : "18px"}
        margin={`0 0 10px`}
      >
      {noteheader}
      </Text>
      <Wrapper
        radius={`10px`}
        shadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
        overflow="hidden"
        bgColor={colorConfigs.basic.white}
        margin={`0 0 30px`}
      >
        <Table
          // rowSelection={rowSelection}
          style={{ width: `100%` }}
          columns={columns}
          loading={isFetching}
          dataSource={productData?.rtn_list || []}
          pagination={{
            current: page,
            pageSize: 10,
            total: productData?.rtn_list?.length || 0,
            showSizeChanger: false,
            onChange: (newPage) => setPage(newPage),
          }}
          scroll={{ x: 1300 }}
        />
      </Wrapper>
      <Modal
        open={noticeModal}
        title={`공지사항`}
        destroyOnClose
        onCancel={() => setNoticeModal((prev) => !prev)}
        footer={
          <Wrapper dr={`row`} ju={`flex-end`}>
            <Button
              style={{ margin: `0 8px 0 0` }}
              // loading={preprocessLoading}
              onClick={() => {
                isnolook(noticeid);
                setNoticeModal(false);
              }}
            >
              오늘그만보기
            </Button>
  
            <Button
              type="primary"
              // loading={preprocessLoading}
              onClick={() => {
                setNoticeModal(false);
              }}
            >
              닫기
            </Button>
          </Wrapper>
        }
      >
      {noticemsg.split("\n").map((line, index) => (
        <p key={index}>{line}</p>
        ))}
      </Modal>
    </Wrapper>
  );
};

export default ProductList;
